
function closeOtherInfo() {
    if (InforObj.length > 0) {
        InforObj[0].set("marker", null);
        InforObj[0].close();
        InforObj.length = 0;
    }
}

function initMap() {
    var map;
    var InforObj = [];
    var centerCords = {
        lat: 52.077920,
        lng: 5.143491
    };
    var markersOnMap = [{
        placeName: "Santeon",
        content: "Herculesplein 38",
        icon: '/app/themes/santeon/dist/images/pin_small.svg',
        LatLng: [{
            lat: 52.077920,
            lng: 5.143491
        }]
    }
        // {
        //     placeName: "Australia (Canberra)",
        //     content: "Adres 3",
        //     icon: '/app/themes/prosper/dist/images/pin_small.png',
        //     LatLng: [{
        //         lat: 51.8235728,
        //         lng: 5.8589925
        //     }]
        // },
        // {
        //     placeName: "Australia (Gold Coast)",
        //     content: "Adres 4",
        //     icon: '/app/themes/prosper/dist/images/pin_small.png',
        //     LatLng: [{
        //         lat: 51.8235728,
        //         lng: 5.8589925
        //     }]
        // },
        // {
        //     placeName: "Australia (Perth)",
        //     content: "Adres 5",
        //     icon: '/app/themes/prosper/dist/images/pin_small.png',
        //     LatLng: [{
        //         lat: 51.8235728,
        //         lng: 5.8589925
        //     }]
        // }
        ];

        window.onload = function() {
            initMap();
        };

        function addMarkerInfo() {
            for (var i = 0; i < markersOnMap.length; i++) {
                var contentString = '<div id="content"><strong>' + markersOnMap[i].placeName +
                '</strong><p>' + markersOnMap[i].content + '</p></div>';

                const marker = new google.maps.Marker({
                    position: markersOnMap[i].LatLng[0],
                    map: map,
                    icon: markersOnMap[i].icon
                });

                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                    maxWidth: 200,
                    pixelOffset: new google.maps.Size(125, 75)
                });

                marker.addListener('click', function() {
                    closeOtherInfo();
                    infowindow.open(marker.get('map'), marker);
                    InforObj[0] = infowindow;
                });
            // marker.addListener('mouseover', function () {
            //     closeOtherInfo();
            //     infowindow.open(marker.get('map'), marker);
            //     InforObj[0] = infowindow;
            // });
            // marker.addListener('mouseout', function () {
            //     closeOtherInfo();
            //     infowindow.close();
            //     InforObj[0] = infowindow;
            // });
        }
    }


    function initMap() {
        map = new google.maps.Map(document.getElementById('map'), {
            zoom: 9,
            center: centerCords,
            styles: [{
                "featureType": "all",
                "stylers": [{
                    "saturation": 0
                },
                {
                    "hue": "#e7ecf0"
                }
                ]
            },
            {
                "featureType": "road",
                "stylers": [{
                    "saturation": -70
                }]
            },
            {
                "featureType": "transit",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "poi",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "water",
                "stylers": [{
                    "visibility": "simplified"
                },
                {
                    "saturation": -60
                }
                ]
            }
            ]
        });
        addMarkerInfo();
    }
};