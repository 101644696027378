(function($) {


  get_viewport_size = function() {
    var e = window,
    a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  };


  stickyNav = function()  {

    // if (get_viewport_size().width > 1200) {
    //   if ( $(window).scrollTop() > 150  ) {
    //     $('body').addClass('stickyNav');
    //   } else {
    //     $('body').removeClass('stickyNav');
    //   }
    // } else if (get_viewport_size().width < 1200) {
    //   // if ( $(window).scrollTop() > 150  ) {
    //   //   $('body').addClass('stickyNav');
    //   // } else {
    //   //   $('body').removeClass('stickyNav');
    //   // }
    // }
  };

  slickSlider = function() {
    $('.slick-slider--projects').slick({
      dots: true,
      appendDots: '.slick-nav',
      infinite: true,
      autoplay: false,
      appendArrows: '.slick-nav',
      prevArrow: '<a class="slick-arrow prev"><svg width="50" height="26" xmlns="http://www.w3.org/2000/svg"><path d="M21.455 25.663l-.025-.007a11.21 11.21 0 01-1.318-.406c-1.158-.417-2.264-.983-3.342-1.574-.59-.325-1.176-.657-1.765-.983-2.227-1.236-4.394-2.577-6.529-3.963a258.473 258.473 0 01-3.481-2.303c-1.431-.962-3.599-2.082-4.418-3.67-.383-.74-.481-1.694.378-2.279C2.789 9.23 4.878 8.301 6.857 7.314c5.076-2.528 10.287-4.863 15.802-6.254.97-.244 1.946-.465 2.924-.68.444-.097 3.628-1.008 3.421.395-.066.44-2.487 1.612-3.143 2.017-1.376.85-5.173 2.998-5.852 3.418-.457.281-3.523 2.004-2.991 2.833.186.29 1.855.306 3.299.272 6.283-.147 16.219.193 17.869.286 7.162.4 5.406.85 7.833 1.153 1.537.203 1.992.451 3.228.945 1.155.461.913 1.345-.261 1.572-2.347.455-12.025.544-21.149.387-3.879-.066-4.258.5-7.708.65-1.636.072-3.105.738-7.557 1.014-.814.051-.921.436-.509.997.29.395.708.724 1.065 1.055 2.248 2.087 4.573 4.092 6.888 6.104l1.452 1.26c.187.162.482.351.534.613.064.322-.318.364-.547.312" fill="#6F5606" fill-rule="evenodd"/></svg></a>',
      nextArrow: '<a class="slick-arrow next"><svg width="50" height="26" xmlns="http://www.w3.org/2000/svg"><path d="M28.545 25.663l.025-.007a11.21 11.21 0 001.318-.406c1.158-.417 2.264-.983 3.342-1.574.59-.325 1.176-.657 1.765-.983 2.227-1.236 4.394-2.577 6.529-3.963a258.473 258.473 0 003.481-2.303c1.431-.962 3.599-2.082 4.418-3.67.383-.74.481-1.694-.378-2.279-1.834-1.248-3.923-2.177-5.902-3.164-5.076-2.528-10.287-4.863-15.802-6.254a99.947 99.947 0 00-2.924-.68c-.444-.097-3.628-1.008-3.421.395.066.44 2.487 1.612 3.143 2.017 1.376.85 5.173 2.998 5.852 3.418.457.281 3.523 2.004 2.991 2.833-.186.29-1.855.306-3.299.272-6.283-.147-16.219.193-17.869.286-7.162.4-5.406.85-7.833 1.153-1.537.203-1.992.451-3.228.945-1.155.461-.913 1.345.261 1.572 2.347.455 12.025.544 21.149.387 3.879-.066 4.258.5 7.708.65 1.636.072 3.105.738 7.557 1.014.814.051.921.436.509.997-.29.395-.708.724-1.065 1.055-2.248 2.087-4.573 4.092-6.888 6.104l-1.452 1.26c-.187.162-.482.351-.534.613-.064.322.318.364.547.312" fill="#6F5606" fill-rule="evenodd"/></svg></a>',
      arrows: false,
      mobileFirst: true,
      speed: 750,
      slidesToShow: 1,
      fade: false,
      slidesToScroll: 1,
      centerMode: false
    });
    $('.slick-slider--tentoonstelling').slick({
      dots: false,
      appendDots: '.slick-nav_show',
      infinite: true,
      autoplay: false,
      prevArrow: '<a class="slick-arrow slick-arrow__show slick-arrow__show--prev prev"><svg width="88" height="46" xmlns="http://www.w3.org/2000/svg"><path d="M37.463 45.57l-.044-.012c-.79-.177-1.574-.44-2.333-.712-2.05-.73-4.008-1.722-5.917-2.758-1.045-.57-2.082-1.15-3.125-1.722-3.942-2.166-7.779-4.515-11.559-6.944a458.046 458.046 0 01-6.163-4.035C5.79 27.7 1.951 25.738.501 22.956c-.678-1.297-.852-2.968.669-3.993 3.247-2.187 6.945-3.815 10.449-5.544C20.606 8.989 29.83 4.898 39.595 2.46c1.717-.427 3.445-.815 5.177-1.191.786-.17 6.423-1.766 6.056.692-.117.77-4.403 2.824-5.564 3.534-2.436 1.49-9.158 5.253-10.36 5.99-.81.492-6.238 3.51-5.296 4.963.33.508 3.284.536 5.84.477 11.124-.258 28.715.338 31.636.5 12.68.702 9.571 1.49 13.868 2.021 2.721.356 3.527.79 5.715 1.656 2.045.808 1.616 2.357-.462 2.755-4.155.797-21.29.953-37.443.678-6.867-.116-7.538.874-13.646 1.139-2.897.126-5.497 1.293-13.38 1.776-1.44.09-1.63.764-.9 1.747.513.692 1.253 1.269 1.885 1.849 3.98 3.657 8.096 7.17 12.195 10.695.855.736 1.712 1.472 2.57 2.208.332.284.854.615.946 1.074.113.565-.563.638-.969.547" fill="#C88A0D" fill-rule="evenodd"/></svg></a>',
      nextArrow: '<a class="slick-arrow slick-arrow__show slick-arrow__show--next next"><svg width="91" height="48" xmlns="http://www.w3.org/2000/svg"><path d="M52.26 47.962c.016-.006.032-.01.045-.013.817-.189 1.628-.47 2.413-.759 2.12-.78 4.145-1.837 6.119-2.941 1.08-.608 2.153-1.228 3.23-1.837 4.078-2.31 8.045-4.816 11.954-7.406a472.492 472.492 0 006.373-4.304c2.62-1.798 6.589-3.891 8.088-6.859.701-1.383.88-3.165-.692-4.259-3.357-2.332-7.182-4.068-10.805-5.912-9.293-4.725-18.833-9.088-28.93-11.688a179.837 179.837 0 00-5.353-1.27c-.813-.182-6.642-1.884-6.263.738.12.822 4.553 3.012 5.754 3.769 2.52 1.588 9.47 5.603 10.714 6.387.836.526 6.45 3.745 5.476 5.295-.341.542-3.397.572-6.04.508-11.503-.275-29.693.36-32.714.534-13.112.748-9.897 1.589-14.34 2.155-2.815.38-3.648.843-5.91 1.766-2.115.862-1.672 2.514.477 2.938 4.297.85 22.015 1.017 38.72.723 7.101-.123 7.795.933 14.111 1.215 2.995.134 5.685 1.379 13.835 1.895 1.49.095 1.686.815.932 1.863-.53.738-1.296 1.353-1.95 1.972-4.115 3.9-8.372 7.647-12.61 11.407-.884.784-1.77 1.57-2.658 2.354-.343.303-.883.656-.978 1.146-.117.602.582.68 1.001.583" fill="#C88A0D" fill-rule="evenodd"/></svg></a>',
      arrows: true,
      mobileFirst: true,
      speed: 300,
      slidesToShow: 1,
      fade: false,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true
        }
      }
      ]
    });
  };

  toggleNav = function() {
    // Togglefunction for the mobile menu. If toggled, a class is added to the body

    $("#nav-trigger").on('click', function(e){
      e.preventDefault();
      if($('body').hasClass('open-nav')) {
        $('body').removeClass('open-nav');
      }
      else {
        $('body').addClass('open-nav');
      }
    });

    $(".menu-item a").on('click', function(e) {
      if( $('body').hasClass('open-nav') ) {
        setTimeout(function(){
          $('body').removeClass('open-nav');
        }, 700);
      }
    });
  };

  magnificPopup = function() {
    $('.gallery, .album-gallery, .acf_gallery_cols').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      }
    });
    $('.image-popup-link').magnificPopup({
      type: 'image'
    });
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: true,
      callbacks: {
        beforeOpen: function() {
          startWindowScroll = $(window).scrollTop();
        },
        close: function() {
          $(window).scrollTop(startWindowScroll);
        }
      }
    });
    $('.popup-modal').magnificPopup({
      type: 'inline',
      preloader: false,
      focus: '#name',

    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function() {
        if($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = '#name';
        }
      }
    }
  });
  };

  masonry = function() {
    $('.isotope').isotope({
      // options
      layoutMode: 'packery',
      itemSelector: '.infographic__block',
      percentPosition: true,
    });
  };

  animateAnchor = function() {
    $('a[href^="#"]:not(.popup-modal)').click(function(){

      var the_id = $(this).attr("href");
      $('html, body').animate({
        scrollTop:$(the_id).offset().top - 100
      }, 1000);

      $('body').removeClass('open-nav');
      return false;
    });
  };

  getUrlParameter = function(sParam) {
    var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  parallaxScene = function() {
     document.querySelectorAll('.scene').forEach((elem) => {
      const modifier = elem.getAttribute('data-modifier');
      basicScroll.create({
        elem: elem,
        from: 0,
        to: 519,
        direct: true,
        props: {
          '--translateY': {
            from: '0',
            to: `${ 10 * modifier }px`
          }
        }
      }).start();
    });
  };

  $.fn.accessibleDropDown = function () {
    var el = $(this);

    /* Make dropdown menus keyboard accessible */

    $("a", el).focus(function() {
      $(this).parents("li").addClass("hover");
    }).blur(function() {
      $(this).parents("li").removeClass("hover");
    });
    $("li", el).on('touchstart', function(e) {
      $(this).addClass("hover");
    });
  };




  storeFilter = function() {
    $('.category-filter').on('click', function(e) {
      e.preventDefault();
      if( $(this).attr('data-category') == '_all') {
        $('.category-filter').removeClass('active');
        $('.single-attractie').removeClass('hidden');
        $(this).addClass('active');
      } else {
        if( $(this).hasClass('active') ) {
          $(this).removeClass('active');
          $('.single-attractie').removeClass('hidden');
        } else {
          $('.category-filter').removeClass('active');
          $(this).addClass('active');
          $('.single-attractie').addClass('hidden');
          $('.single-attractie[data-group*="' + $(this).attr('data-category') + '"]').removeClass('hidden');
        }
      }
    });
  }
})(jQuery);




